import lazysizes from 'lazysizes';
import imageNames from './images';

const imagesContainer = document.querySelector('.images');

const createEmptyImages = () => {
  imagesContainer.innerHTML = '';

  imageNames.map((imageName) => {
    const img = new Image();
    const placeholderDiv = document.createElement('div');

    img.dataset.src = `./images/high-res/${imageName}`;
    img.classList.add('inline');
    placeholderDiv.appendChild(img);
    imagesContainer.appendChild(placeholderDiv);
  });
};

const loadImages = (entries) => {
  entries.forEach((entry) => {
    const img = entry.firstChild;

    img.classList.add('lazyload');
  });
};

createEmptyImages();
const placeholders = Array.from(document.querySelector('.images').children);
loadImages(placeholders);
